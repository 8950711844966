import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './login/login.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SmartRealEstate-UI';
  
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('pt');
    this.translate.use(localStorage.getItem('lang') || 'pt')
  }




  // loggedUserId: number = 0;
  // constructor(private router: Router) {
  //   console.info("testing  app component");
  //   // this.loggedUserId = Number(localStorage.getItem("LoggedUserId"));
  //   // if (!(this.loggedUserId > 0)) {
  //   //   this.router.navigate(['/login'])
  //   // }
  // }


}
