<section style="overflow-x: hidden; overflow-y: hidden;">
  <div class="d-flex- container">
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-center w-100 h-100">
          <div class="reset-password-card mt-5">
            <div class="container mt-5">
              <div class="card">
                <div class="card-header bg-light-" style="background-color: #afd7ff">
                  <h3 class="text-center fs-4 pt-2 mb-2">{{'Tour Confirmation Status' | translate}}</h3>
                </div>
                <div class="card-body">
                  <div *ngIf="messageDisplayed; else tourDetails">
                    <h2 class="massage text-center">{{ message }}</h2>
                  </div>
                  <ng-template #tourDetails>
                    <div *ngFor="let request of properties">
                      <div class="row">
                        <div class="col-md-4">
                          <img [src]="request.imagePathUrl ? request.imagePathUrl : defaultImageUrl" alt="" class="img-fluid images-flats">
                        </div>
                        <div class="col-md-5">
                          <p class="fw-bold">{{ request.property }}</p>
                          <p class="text-muted"><b><i class="fa-regular fa-calendar pe-2"></i></b>{{ request.starttime | date:'EEEE dd, yyyy'  }}</p>
                          <p class="text-muted"><b><i class="fa-regular fa-clock pe-2"></i></b> {{ request.starttime | date: 'shortTime' }} - {{ request.endtime | date: 'shortTime' }}</p>
                        </div>
                        <div class="col-md-3">
                          <span class="btn status-confirmed px-2 py-2 rounded-2" [ngClass]="getStatusClasses(request.status)">{{ request.status | translate}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <p class="h6 pt-3">{{'Agent' | translate}}: <span class="sent_blue_color fs-6">{{ formatListingAgentName(request.listingAgentName) }}</span></p>
                        </div>
                        <div class="col-md-6">
                          <p class="h6 pt-3 ps-1">{{'Company' | translate}}: <span class="sent_blue_color fs-6">{{ request.companyName }}</span></p>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-12">
                          <p>{{'Please Select one of the options below' | translate}}</p>
                          <div>
                            <button type="button" class="btn btn-outline-success status_btn acc_btn rounded-3 me-2" (click)="AcceptTour(request)" style="font-size: 14px; font-weight: 700;">
                              <span>{{ 'Accept' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-outline-danger status_btn rej_btn rounded-3" (click)="RejectTour(request)" style="font-size: 14px; font-weight: 700;">
                              <span>{{ 'Reject' | translate }}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
