import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SentemailRoutingModule } from './sentemail-routing.module';
import { EmailRequestComponent } from './email-request/email-request.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ScheduleModule, RecurrenceEditorModule } from '@syncfusion/ej2-angular-schedule';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BookTourModule } from '../app-user/tour/book-tour/book-tour.module';
import { MatStepperModule } from '@angular/material/stepper';


@NgModule({
  declarations: [
    EmailRequestComponent
  ],
  imports: [
    CommonModule,
    SentemailRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatTableModule,
    MatNativeDateModule,
    MatCardModule,
    MatSelectModule,NgxMaterialTimepickerModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    MatAutocompleteModule,
    DropDownListModule,
    DateTimePickerModule,
    ScheduleModule,
    RecurrenceEditorModule,
    MatSortModule,
    GoogleMapsModule,
    TranslateModule,
    MatPaginatorModule,
    MatStepperModule

  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },],
})
export class SentemailModule { }
