import { PaymentModule } from './pwd-reset/payment-due/payment.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/common/auth-guard';
import { ContactviewComponent } from './app-user/contacts/contactview/contactview.component';
import { EmailRequestComponent } from './sentemail/email-request/email-request.component';
// import { PaymentIntentComponent } from './pwd-reset/payment-intent/payment-intent.component';

const routes: Routes = [
  // { path: '', component: AppComponent, children: [ { path: 'user', redirectTo: '/', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./app-user/app-user.module').then(m => m.AppUserModule), canActivate: [AuthGuard] },
  // ] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  // { path: 'payment-intent/:reset_user_email', component: PaymentIntentComponent },
  { path: 'Password_Reset/:reset_user_email/:reset_user_token/:reset_user_roleId', loadChildren: () => import('./pwd-reset/pwd-reset.module').then(m => m.PwdResetModule) },
  // { path: '**', redirectTo: '/login', pathMatch: 'full' }
  { path: 'contact/:id', component: ContactviewComponent },
  { path: 'PaymentDue', loadChildren: () => import('./pwd-reset/payment-due/payment.module').then(m => m.PaymentModule) },
  { path: 'email-request/:tourListingId/:email', component: EmailRequestComponent },
  { path: 'email-request/:tourListingId/:email', loadChildren: () => import('./sentemail/sentemail.module').then(m => m.SentemailModule), },
  { path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
