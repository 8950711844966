import { Location } from '@angular/common';
import { Component, Inject, Optional, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef,MatDialogTitle,MatDialogContent,MatDialogActions,MatDialogClose,} from '@angular/material/dialog';
import { MyToursComponent } from '../my-tours.component';
import { AdminsService } from 'src/app/services/Admins/admins.service';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { AlertService } from 'src/app/services/Alert/alert.service';


export interface GetTourListingData
{
  starttime: Date;
  endtime: Date;
  timeSlot: string;
  date:Date;
  id : number;
  tourListingId : number;
}


@Component({
  selector: 'app-accept-tour',
  templateUrl: './accept-tour.component.html',
  styleUrls: ['./accept-tour.component.scss']
})
export class AcceptTourComponent {
  data: GetTourListingData;
  tourId : number;
  AcceptourData: any;
  stepper: MatStepper;



  constructor( public closing: MatDialogRef<MyToursComponent>,public dialogRef: MatDialogRef<AcceptTourComponent>,private alertservice: AlertService,
    private router: Router, @Optional() @Inject(MAT_DIALOG_DATA) public tourData: GetTourListingData[],
  private tourService : AdminsService)
  {
    if (Array.isArray(tourData)) {
      this.data = tourData[0];
    } else {
      this.data = tourData;
    }
    console.log('Assigned data:', this.data);
  }

  CloseIcon() {
    this.dialogRef.close()
  }

  AcceptTour(rowData : GetTourListingData)  {
    this.tourService.AcceptTourByTourId(rowData.tourListingId).subscribe((res : any) => {
      this.AcceptourData = res;
      this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
      // this.alertservice.default('Your tour request has been Accepted as per your request')
    })
    this.dialogRef.close('accepted');
    // location.reload();
   // this.alertservice.default('Your tour request has been Accepted as per your request')

  }

}
