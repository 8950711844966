import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';


// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXtfcXRXRWdeUkV2W0o=');

if(environment.production){
  enableProdMode()
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));







