<div mat-dialog-title class="d-flex justify-content-between"  style="background-color: #E3F2F9;">
    <div class="custom-modal-title">
      <h1 class="fw-bold mb-0 text-start">
        {{'RejectTour'|translate}}
      </h1>
    </div>

    <div class="custom-button-close-icon" (click)="CloseIcon()">
        <!-- <button mat-mini-fab color="warn">
          <mat-icon style="align-items: end; cursor: pointer">close</mat-icon>
        </button> -->
        <i class="fa-solid fa-x me-2 mt-1" style="align-items: end; cursor: pointer"></i>

      </div>
  </div>


  <mat-dialog-content class="mat-typography mt-3">
    <div class=" text-center">
        <img src="../../assets/1674634041_grizly-club-p-krestik-klipart-10.jpg"
        alt="" class="img-fluid reject_img">
    <h3 class="mt-3 mb-0 text-dark" style="font-weight: 500;">{{'DoyouwanttoRejectTourRequest'|translate}}</h3>
        <h3 class="mb-0 text-dark">
          <b>{{data?.date| date:'MMM dd, yyyy'}}</b> {{'from'|translate}}
          <b> {{data?.starttime | date: 'shortTime' }} - {{
            data?.endtime | date: 'shortTime' }}</b>
        </h3>
    </div>
    </mat-dialog-content>



<div mat-dialog-actions class="justify-content-center">
    <div class="filter_btn justify-content-center">
        <button
        class="btn px-5 mb-4 py-2  text-light add_button1" (click)="RejectTour(data)">
        {{'Yes'|translate}}
    </button>
    <button type="button"
        class="btn px-5 py-2 ms-2 no_btn mb-4" (click)="CloseIcon()"
        >{{'No'|translate}}</button>
      </div>
  </div>

