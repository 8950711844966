import { Component, Input, OnInit } from "@angular/core"; 
import { ValidationService } from "src/shared/validation.service";

@Component({
  selector: "app-validation-modal",
  templateUrl: "./validation-modal.component.html",
  styleUrls: ["./validation-modal.component.css","../../../smartmlsp/components/listing/add-listing/add-listing.component.css"],
})
export class ValidationModalComponent implements OnInit {
  @Input() errors: string[] | undefined;

  constructor(private validationService: ValidationService) {}

  ngOnInit() {}

  closeModal() {
    this.validationService.closeErrorPopup();
  }
}
