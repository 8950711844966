import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthGuard } from '../auth-guard';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private _authGuard: AuthGuard) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            // Get the auth token from the service.
        const authToken = localStorage.getItem("LoggedUserToken");
        /* * The verbose way: */
        const authReq = req.clone({ headers: req.headers.set('Authorization', "Bearer " + authToken), withCredentials: false });
        // Clone the request and set the new header in one step.
        authReq.headers.append('Content-Type', (req.url.toLowerCase().indexOf('upload') === -1) ? 'application/json' : 'multipart/form-data');
        // send cloned request with header to the next handler.
        return next.handle(authReq).pipe(tap(event => this.handleResponse(req, event), error => this.handleError(req, error)));
    }

    //success handler if any required
    handleResponse(req: any, event: any) { }

    handleError(req: any, err: any) {
        let errorMessage = '';

        if (err instanceof HttpErrorResponse) {
            if (!err.status) {
                // client-side error
                return null;
            }
            else if (err.status == 401) {
                errorMessage = "Your session timed out. Please login again.";
                //handle unauthorized errors here
                this._authGuard.logout();
            }
            else if (err.status == 409) {
                //Handle known conflict errors here
            }
            else {
                // internal server errors
            }
            return throwError(errorMessage);
        }
        return null;
    }
}
