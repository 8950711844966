<div class="d-flex align-items-center justify-content-end">
  <button type="button" class="btn-close close pull-right fs-6 mt-3 me-3" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body mt-1 pt-0 px-4">
  <div class="model-cards pb-3 px-3">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <img src="assets/img/icon-1.png" alt="" class="img-fluid logout-img">
        </div>
      </div>
      <div class="col-md-12">
        <h2 class="model-main-heading">{{'ValidationErrors' | translate}}</h2>
      </div>
      <div class="col-md-12">
        <div *ngIf="errors.length > 0">
          <ul class="list-unstyled revolution-ul" *ngFor="let error of errors">
            <li class="revolution-li"><i class="fa-solid fa-check"></i>{{ error | translate }} {{'fieldisrequired' | translate}}</li>
          </ul>
        </div>
        <div *ngIf="errors.length === 0">
          <ul class="list-unstyled revolution-ul">
            <li class="revolution-li"><i class="fa-solid fa-check"></i>{{'Allfieldsarevalid' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="rounded-pill btn continue-btn w-100" (click)="closeModal()">
          {{'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>