<div class="mx-5 shadow bg-white border" style="border-radius: 25px">
  <div class="container-fluid">
    <section>
      <div class="p-3 mx-2">
        <div class="d-flex justify-content-between position-relative">
          <div class="d-flex position-absolute w-100 justify-content-end">
            <button (click)="gotoback()" class="btn btn-outline btn-back rounded-3 px-3 my-1" style="z-index:2">
              <i class="fa-solid fa-arrow-left pe-2"></i>{{'Back'|translate}}</button>
          </div>

          <mat-tab-group class="w-100" mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="selectedTabIndex" animationDuration="2">
            <mat-tab label="CONTACT DETAILS">
              <div class="container-fluid">
                <div class="pt-3">
                  <section>
                    <div class="row mt-2 gap-5 ">
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'FirstName'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.firstName}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'LastName'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.lastName}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'Email'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.email}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'PhoneNumber'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.phoneNumber}}</p>
                      </div>

                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'Mobilenumber'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.mobileNumber}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'Whatsapp'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.whatsapp}}</p>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="pt-4">
                  <section>
                    <div class="d-flex justify-content-between">
                      <h3 class="flex-fill fw-bold mb-0 fs-6-">{{'ADDRESS'|translate}}</h3>
                    </div>
                    <div class="row mt-2 gap-5">
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'District'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.districtId}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'Municipality'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.muncipalityId}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'Parish'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.parishId}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'Zone'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.zone}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'streetName'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.streetName}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'doorNo'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.doorNo}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'FloorNo'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.floorNo}}</p>
                      </div>
                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'Dooridentifier'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.doorIdentifier}}</p>
                      </div>

                      <div class="col-md-2">
                        <h4 class="viewCompany-h4 fs14 fw-bold mb-0">{{'ZipCode'|translate}}</h4>
                        <p class="fs14 mb-0">{{ContactData?.zipCode}}</p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </section>
  </div>
</div>
