import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ValidationModalComponent } from '../smartmlsp/layout/validation-modal/validation-modal.component';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  errorModalRef: BsModalRef;
  private completedSteps: { [step: string]: boolean } = {};
  touchedSteps: { [step: string]: boolean } = {};
  activeStep = new BehaviorSubject('');

  constructor(private modalService: BsModalService) {}

  showErrorPopup(errors: string[]) {
    this.errorModalRef = this.modalService.show(ValidationModalComponent, {
      initialState: { errors },
      class: 'modal-dialog-centered modal-lg',
    });
  }

  closeErrorPopup() {
    this.errorModalRef.hide();
  }

  markStepAsCompleted(step: string) {
    this.completedSteps[step] = true;
  }

  markStepAsIncompleted(step: string) {
    this.completedSteps[step] = false;
  }

  isStepCompleted(step: string): boolean {
    return this.completedSteps[step];
  }

  markStepAsTouched(step: string) {
    this.touchedSteps[step] = true;
  }

  markStepAsUntouched(step: string) {
    this.touchedSteps[step] = false;
  }

  isStepTouched(step: string): boolean {
    return this.touchedSteps[step] || false;
  }

  setActiveStep(step: string) {
    this.activeStep.next(step);
  }

  isActiveStep() {
    return this.activeStep.asObservable();
  }

  resetCompletedSteps() {
    this.completedSteps = {};
    this.touchedSteps = {};
    this.setActiveStep(undefined)
  }

  // check validity
  checkValidity(
    formGroupValidity: any,
    stepName: string,
    formgroup: FormGroup = null,
    controlName: string = 'isCompleted'
  ) {
    if (formGroupValidity) {
      formgroup?.get(controlName).patchValue(true);
      this.markStepAsCompleted(stepName);
    } else {
      formgroup?.get(controlName).patchValue(false);
      this.markStepAsIncompleted(stepName);
    }
  }
}
