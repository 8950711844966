import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from 'src/common/http-interceptors';


@NgModule({
  declarations: [],
  imports: [
    CommonModule, HttpClientModule
  ],
  providers: [httpInterceptorProviders],
  exports: [
    CommonModule
  ]
})
export class SharedModule { }
