import { Component, ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'src/common/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LoaderComponent {
  constructor(public _loader: LoaderService) {
    // console.info(this._loader.loaderState, this._loader.show())
    // console.info(this._loader.loaderState.subscribe({ next: res=> console.info(res, ":response"), error: console.info }))
    // console.info(this._loader.loaderState, this._loader.hide())
  }
}
