import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';

export const BYPASS_LOADER = new HttpContextToken(() => false);
@Injectable({
    providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
    private count: number = 0;
    constructor(private _loaderService: LoaderService) { }
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.count++;
        if (req.context.get(BYPASS_LOADER) === false)
        if (this.count == 1)
            this.onStart();
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.count--;
                if (this.count == 0)
                    this.onEnd();
            }
        }, (err: any) => {
            this.count--;
            if (this.count == 0)
                this.onEnd();
        }));
    }
    private onStart(): void {
        this.showLoader();
    }
    private onEnd(): void {
        this.hideLoader();
    }
    private showLoader(): void {
        this._loaderService.show();
    }
    private hideLoader(): void {
        this._loaderService.hide();
    }
}
