import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpContext } from '@angular/common/http';
import { BYPASS_LOADER } from 'src/common/http-interceptors/loader-interceptor';
import { environment } from 'src/environments/environment'

interface GetParams {
  path?: string;
  skipInterceptorServices: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  API_URL = environment.BaseAPIUrl;
  API_URL_Listing = environment.BaseAPIUrlTeamA;


  constructor(private http: HttpClient, protected httpBackend: HttpBackend) {}

  private setToDirectBackend() { this.http = new HttpClient(this.httpBackend); }
  get<T>(path: string, skipInterceptorServices: boolean = false) {
    // if (skipInterceptorServices) { this.setToDirectBackend(); }
    var contextLoad = { context: new HttpContext().set(BYPASS_LOADER, skipInterceptorServices ? true : false) };
    return this.http.get<T>(`${this.API_URL}${path}`, contextLoad);
  }

  post_listing<T>(path: string, body: T) {
    return this.http.post<T>(`${this.API_URL_Listing}${path}`, body);
  }


  post<T>(path: string, body: T) {
    return this.http.post<T>(`${this.API_URL}${path}`, body);
  }

  put<T>(path: string, body: T) {
    return this.http.put<T>(`${this.API_URL}${path}`, body);
  }

  delete<T>(path: string) {
    return this.http.delete<T>(`${this.API_URL}${path}`);
  }
}
